import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { MyGroupsSidebarItem } from '@backstage/plugin-org';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  SidebarSubmenu,
  SidebarSubmenuItem,
  useSidebarOpenState,
  Link,
} from '@backstage/core-components';
import { createIcon } from '@dweber019/backstage-plugin-simple-icons';

//Icons
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import GroupIcon from '@material-ui/icons/People';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonIcon from '@mui/icons-material/Person';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import CategoryIcon from '@mui/icons-material/Category';
import {
  siVault, 
  siHarbor, 
  siArgo, 
  siBitbucket, 
  siJira, 
  siBackstage, 
  siGrafana,
  siSonarqube,
  siApacheairflow
} from 'simple-icons';
import siCrossplaneLogo from "../../assets/CrossplaneLogo"
import OpenSourceLogo from "../../assets/OpenSourceLogo"
import GlSmallLogo from '../../assets/GlSmallLogo';


const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <GlSmallLogo/> : <GlSmallLogo/>}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        <SidebarItem icon={HomeIcon} to="/" text="Home" />
        <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog" />
        <MyGroupsSidebarItem
          singularTitle="My Group"
          pluralTitle="My Groups"
          icon={GroupIcon}
        />
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarItem icon={LibraryBooks} text="Platform Docs">
          <SidebarSubmenu title="Platform Docs">
          <SidebarSubmenuItem
              title="Platform Admin"
              to="docs/default/component/platform-admin-docs"
              icon={SupervisorAccountIcon}
            />
            <SidebarSubmenuItem
              title="Developer/User"
              to="docs/default/component/platform-user-docs"
              icon={PersonIcon}
            />
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem icon={OpenSourceLogo} text="Open Source">
          <SidebarSubmenu title="Open Source">
            <SidebarSubmenuItem
              title="TFE Plugin"
              to="https://github.com/globallogicuki/globallogic-backstage-plugins"
              icon={createIcon(siBackstage, false, { style: { width: '20px', height: '20px' } })}
            />
            <SidebarSubmenuItem
              title="SonarQube Provider"
              to="https://github.com/globallogicuki/provider-sonarqube"
              icon={siCrossplaneLogo}
            />
            <SidebarSubmenuItem
              title="Harbor Provider"
              to="https://github.com/globallogicuki/provider-harbor"
              icon={siCrossplaneLogo}
            />
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem icon={CategoryIcon} text="Internal Products">
          <SidebarSubmenu title="Products">
            <SidebarSubmenuItem
              title="SCOPE"
              to="https://scope.gluki.io"
              icon={WebAssetIcon}
            />
            <SidebarSubmenuItem
              title="SDLC.ai"
              to="https://sdlc.ai.gluki.io"
              icon={WebAssetIcon}
            />
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarDivider />
        <SidebarScrollWrapper>
          <SidebarItem icon={createIcon(siJira, false, { style: { width: '20px', height: '20px' } })} to="https://foresttechnologies.atlassian.net/jira/projects" text="JIRA" />
          <SidebarItem icon={createIcon(siBitbucket, false, { style: { width: '20px', height: '20px' } })} to="https://bitbucket.org" text="VCS" />
          <SidebarItem icon={createIcon(siArgo, false, { style: { width: '20px', height: '20px' } })} to="https://ci.gluki.io" text="CI" />
          <SidebarItem icon={createIcon(siArgo, false, { style: { width: '20px', height: '20px' } })} to="https://cd.gluki.io" text="CD" />
          <SidebarItem icon={createIcon(siGrafana, false, { style: { width: '20px', height: '20px' } })} to="https://observability.gluki.io/goto/SYSI9z5Hg?orgId=1" text="Logs" />
          <SidebarItem icon={createIcon(siGrafana, false, { style: { width: '20px', height: '20px' } })} to="https://observability.gluki.io/dashboards" text="Dashboards" />
          <SidebarItem icon={createIcon(siHarbor, false, { style: { width: '20px', height: '20px' } })} to="https://registry.gluki.io" text="Container Registry" />
          <SidebarItem icon={createIcon(siVault, false, { style: { width: '20px', height: '20px' } })} to="https://secrets.gluki.io" text="Secrets" />
          <SidebarItem icon={createIcon(siSonarqube, false, { style: { width: '20px', height: '20px' } })} to="https://sonarqube.gluki.io" text="Sonarqube" />
          <SidebarItem icon={createIcon(siApacheairflow, false, { style: { width: '20px', height: '20px' } })} to="https://airflow.gluki.io" text="Airflow" />
        </SidebarScrollWrapper>
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
