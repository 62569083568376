import {
    createUnifiedTheme,
    genPageTheme,
    palettes,
    shapes,
  } from '@backstage/theme';
  
  export const globallogicTheme = createUnifiedTheme({
    palette: {
      ...palettes.light,
      primary: {
        main: '#414042',
      },
      secondary: {
        main: '#565a6e',
      },
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      infoBackground: '#00000',
      navigation: {
        background: '#000000',
        indicator: '#F37037',
        color: '#d5d6db',
        selectedColor: '#ffffff',
      },
    },
    defaultPageTheme: 'home',
    fontFamily: 'Trebuchet MS',
    /* below drives the header colors */
    pageTheme: {
      home: genPageTheme({ colors: ['#F37037', '#F37037'], shape: shapes.wave2 }),
      documentation: genPageTheme({ colors: ['#F37037'], shape: shapes.wave2 }),
      tool: genPageTheme({ colors: ['#F37037'], shape: shapes.wave2 }),
      service: genPageTheme({ colors: ['#F37037'], shape: shapes.wave2 }),
      website: genPageTheme({ colors: ['#F37037'], shape: shapes.wave2 }),
      library: genPageTheme({ colors: ['#F37037'], shape: shapes.wave }),
      other: genPageTheme({ colors: ['#F37037'], shape: shapes.wave }),
      app: genPageTheme({ colors: ['#F37037'], shape: shapes.wave }),
      apis: genPageTheme({ colors: ['#F37037'], shape: shapes.wave }),
    },
  });
  