import React from 'react';
import { Grid, makeStyles, Typography, Card, CardContent, CardActions, CardHeader, Button } from '@material-ui/core';
import { Content, Page } from '@backstage/core-components';

import { HomePageCompanyLogo  } from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import {
  SearchContextProvider,
} from '@backstage/plugin-search-react';
import { DevQuote } from '@parsifal-m/plugin-dev-quotes-homepage';

//Icons and Logos
import GlFullLogo from '../../assets/GlFullLogo';

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '100vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    padding: '8px 0',
    margin: 'auto',
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
  }));


export const HomePage = () => {
  const classes = useStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center">
            <HomePageCompanyLogo
              logo={<GlFullLogo/>}
            />
            <Grid item xs={12} justifyContent='center'>
              <HomePageSearchBar
                classes={{ root: classes.searchBar }}
                InputProps={{ classes: { notchedOutline: classes.searchBarOutline } }}
                placeholder="Search"
              />
            </Grid>
            <Grid container style={{ 'margin': '0px' }}>
              <Grid item xs={12} md={12}>    
                <Card title="New Here?">
                  <CardHeader
                  title="New here?"
                  />
                  <CardContent>
                    <Typography variant="body1">
                      New to the platform?  The below links will take you to documentation that will guide you through the platform.  Whether you're a new user or an operator, you'll find the resources you need here.
                    </Typography>
                  </CardContent>
                  <Grid container style={{ 'padding': '10px' }}>
                    <Grid item xs={4} md={4}>
                      <Card
                        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                      >
                        <CardHeader
                          title="User"
                        />
                        <CardContent>
                          If you're a new user looking to host an app or work on an existing app that is running on the platform, Please read our new user documentaiton.
                        </CardContent>
                        <CardActions>
                          <Button color="primary" href="docs/default/component/platform-user-docs">
                            Get Started
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Card
                        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                      >
                        <CardHeader
                          title="Operator"
                        />
                        <CardContent>
                          If you're a new operator looking to support the development of new of existing or functionality of the platform, Please read our new operator documentaiton.
                        </CardContent>
                        <CardActions>
                          <Button color="primary" href="docs/default/component/platform-admin-docs">
                            Get Started
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Card
                        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                      >
                        <CardHeader
                          title="OSS Contributor"
                        />
                        <CardContent>
                         If you're looking to contribute to our OSS products, Please read our OSS documentaiton.
                        </CardContent>
                        <CardActions>
                          <Button color="primary" >
                            Get Started
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              <Card>
                <CardHeader title="Dev Quote"/>
                <CardContent>
                  <DevQuote />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  )
};

