import React from 'react';

const OpenSourceLogo = () => {

  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 126.8 122.88"
    width="20"
    height="20"
  >
    <path
      fill="currentColor"
      d="M69.4 78.06c3.92-1.6 6.86-4.61 8.47-8.23 1.65-3.71 1.89-8.04.32-12.12v-.01c-1.56-4.07-4.64-7.13-8.35-8.78-3.7-1.65-8.04-1.89-12.12-.32-4.07 1.56-7.13 4.64-8.78 8.35-1.65 3.7-1.89 8.04-.32 12.12v.01l.02-.01c.8 2.08 2.03 3.95 3.58 5.5 1.57 1.57 3.44 2.81 5.5 3.6 2.36.9 3.54 3.54 2.64 5.9l.02.01-13.75 35.83a4.59 4.59 0 0 1-5.92 2.66c-.14-.05-.27-.11-.4-.17-16.15-6.32-28.25-18.54-34.79-33.22-6.62-14.83-7.57-32.16-1.3-48.48.05-.14.11-.27.17-.4 6.32-16.15 18.54-28.25 33.22-34.79C52.44-1.1 69.78-2.05 86.1 4.22c12.31 4.72 22.3 12.87 29.3 22.93a63.6 63.6 0 0 1 11.38 35.56v.04h.02v1.09c0 .16-.01.31-.02.46-.05 3.64-.41 7.27-1.09 10.86a64 64 0 0 1-3.12 10.94l.02.01a63.3 63.3 0 0 1-14.36 22.12 63.5 63.5 0 0 1-22.12 14.36c-2.37.9-5.02-.29-5.92-2.66L66.43 84.09c-.9-2.37.29-5.02 2.66-5.92.1-.04.2-.08.31-.11m16.83-4.49a24.92 24.92 0 0 1-9.74 11.12l10.55 27.49c5.48-2.66 10.43-6.2 14.69-10.46a54.2 54.2 0 0 0 12.28-18.89l.02.01v-.01a54.702 54.702 0 0 0 3.61-18.64c-.01-.12-.01-.24-.01-.36v-1.09h.02c-.13-10.9-3.6-21.48-9.77-30.35-5.98-8.6-14.52-15.55-25.03-19.59-13.99-5.37-28.82-4.57-41.48 1.07-12.58 5.6-23.04 15.96-28.44 29.76-.03.11-.07.23-.11.34-5.37 13.99-4.57 28.82 1.07 41.49 5.06 11.37 14.01 21.01 25.88 26.75l10.56-27.5a25 25 0 0 1-4.59-3.61 24.9 24.9 0 0 1-5.66-8.73l.02-.01c-2.47-6.44-2.1-13.27.5-19.11a24.89 24.89 0 0 1 13.41-12.97c.3-.15.61-.28.95-.36 6.3-2.28 12.95-1.87 18.65.67A24.89 24.89 0 0 1 86.58 54c.15.3.28.62.36.95a25.02 25.02 0 0 1-.71 18.62"
    ></path>
    <path
      fill="currentColor"
      d="M70.71 82.46c10.53-4.04 15.78-15.85 11.74-26.38-4.03-10.53-15.84-15.78-26.37-11.74-10.52 4.04-15.78 15.85-11.74 26.37 2.07 5.4 6.34 9.67 11.74 11.74l-13.75 35.83C12.01 106.65-3.13 72.65 8.51 42.33 20.14 12.01 54.15-3.13 84.47 8.51c23.16 8.89 37.47 30.84 37.74 54.23v1.09a58.9 58.9 0 0 1-3.91 20.64 58.77 58.77 0 0 1-33.82 33.82z"
    ></path>
  </svg>
  );
};

export default OpenSourceLogo;
