import React from 'react';

const siCrossplaneLogo = () => {

  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="20"
    height="20"
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 256 540"
  >
    <defs>
      <path
        id="path-1"
        d="M.137 122.022c-.18 4.155-.172 8.316.027 12.47C.11 135.557 0 136.61 0 137.69v210.042c0 40.169 32.785 73.036 72.858 73.036h109.65c40.058 0 72.857-32.867 72.857-73.036V137.691c0-1.23-.136-2.407-.191-3.61.199-4.017.204-8.041.014-12.059C252.098 54.1 196.179 0 127.669 0 59.145 0 3.227 54.1.137 122.009z"
      ></path>
      <path
        id="path-3"
        d="M.137 122.022c-.18 4.155-.172 8.316.027 12.47C.11 135.557 0 136.61 0 137.69v210.042c0 40.169 32.785 73.036 72.858 73.036h109.65c40.058 0 72.857-32.867 72.857-73.036V137.691c0-1.23-.136-2.407-.191-3.61.199-4.017.204-8.041.014-12.059C252.098 54.1 196.179 0 127.669 0 59.145 0 3.227 54.1.137 122.009z"
      ></path>
      <path
        id="path-5"
        d="M.137 122.022c-.18 4.155-.172 8.316.027 12.47C.11 135.557 0 136.61 0 137.69v210.042c0 40.169 32.785 73.036 72.858 73.036h109.65c40.058 0 72.857-32.867 72.857-73.036V137.691c0-1.23-.136-2.407-.191-3.61.199-4.017.204-8.041.014-12.059C252.098 54.1 196.179 0 127.669 0 59.145 0 3.227 54.1.137 122.009z"
      ></path>
    </defs>
    <path
      fill="currentColor"
      d="M126.759 173.662c-17.689.03-32.008 14.386-31.993 32.074v301.878a32.034 32.034 0 0 0 31.993 32.074c17.678 0 32.006-14.356 32.006-32.074V205.736c.015-17.688-14.304-32.044-31.993-32.074"
    ></path>
    <path
      fill="currentColor"
      d="M255.18 134.095c.185-4.018.185-8.041 0-12.059C252.117 54.114 196.199 0 127.675 0S3.232 54.114.142 122.036c-.194 4.154-.19 8.315.014 12.469-.04 1.066-.15 2.12-.15 3.2v210.042c0 40.168 32.785 73.036 72.858 73.036h109.65c40.058 0 72.843-32.868 72.843-73.036V137.704c0-1.23-.123-2.406-.177-3.61"
    ></path>
    <g transform="translate(.635 .014)">
      <mask id="mask-2" fill="currentColor">
        <use xlinkHref="#path-1"></use>
      </mask>
      <path
        fill="currentColor"
        d="M331.874 126.028 15.19 443.465l-93.79-94.023L238.07 32.006z"
        mask="url(#mask-2)"
      ></path>
    </g>
    <g transform="translate(.635 .014)">
      <mask id="mask-4" fill="currentColor">
        <use xlinkHref="#path-3"></use>
      </mask>
      <path
        fill="currentColor"
        d="m237.783 31.705-316.67 317.437-116.704-116.992L121.052-85.286z"
        mask="url(#mask-4)"
      ></path>
    </g>
    <g transform="translate(.635 .014)">
      <mask id="mask-6" fill="currentColor">
        <use xlinkHref="#path-5"></use>
      </mask>
      <path
        fill="currentColor"
        d="m425.964 220.351-316.67 317.423-93.79-94.022 316.67-317.423z"
        mask="url(#mask-6)"
      ></path>
    </g>
    <path fill="currentColor" d="m103.448 421.138 55.508 55.645v-56.11z"></path>
  </svg>
  );
};

export default siCrossplaneLogo;
